import PropTypes from 'prop-types'

const {shape, string} = PropTypes

const html = shape({
	html: shape({
		content: string.isRequired,
	}).isRequired,
}).isRequired

export default {
	...PropTypes,
	html,
}
