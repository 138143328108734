import React from 'react'
import PropTypes from '../../prop-types'
import {graphql} from 'gatsby'

import Image from './image'
import {DangerousHTML} from './html'
import Video from '../video'

const Film = ({vimeoId, image, title, status, description}) => (
	<div>
		{vimeoId && (
			<div className="inset film-video">
				<Video title={title} vimeoId={vimeoId} />
			</div>
		)}
		<div className="film-news">
			{image && (
				<div className="film-news-image">
					<Image {...image} />
				</div>
			)}
			<div className="film-news-copy">
				<h4 className="film-news-title">{title}</h4>
				<h5 className="film-news-subtitle">
					<DangerousHTML>{status.html.content}</DangerousHTML>
				</h5>
				{description && (
					<DangerousHTML>{description.html.content}</DangerousHTML>
				)}
			</div>
		</div>
	</div>
)

Film.propTypes = {
	vimeoId: PropTypes.string.isRequired,
	image: PropTypes.shape(Image.propTypes).isRequired,
	title: PropTypes.string.isRequired,
	status: PropTypes.html,
	description: PropTypes.html.isRequired,
}

export default Film

export const contentfulFilmFragment = graphql`
	fragment ContentfulFilmFragment on ContentfulFilm {
		title
		vimeoId
		image: mainImage {
			...ContentfulImageFragment
		}
		status {
			html: childMarkdownRemark {
				content: html
			}
		}
		description {
			html: childMarkdownRemark {
				content: html
			}
		}
	}
`
