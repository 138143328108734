import React from 'react'
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../layout'
import SEO from '../components/seo'
import Film from '../components/contentful/film'

const FilmsPage = ({data}) => (
	<Layout>
		<Helmet>
			<body className="films-page" />
		</Helmet>
		<SEO {...data.metadata} />
		{data.films.items.map(film => (
			<Film key={film.id} {...film} />
		))}
	</Layout>
)

export default FilmsPage

export const query = graphql`
	{
		metadata: contentfulMetadata(title: {eq: "Films"}) {
			...ContentfulMetadataFragment
		}
		films: contentfulList(name: {eq: "Films Page Films"}) {
			items {
				... on ContentfulFilm {
					...ContentfulFilmFragment
					id
				}
			}
		}
	}
`
